

import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { geTemporaryPaymentUpdate } from "../store/checkout";

const useInsertTemporarySession = (movieParam, iformacionBan = "") => {
    const dispatch = useDispatch();
    const checkoutState = useSelector((state) => state.checkout);
    const homeState = useSelector((state) => state.home);
    const authUser = useSelector((state) => state.auth);

    const insertTemporarySession = useCallback(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        // Extraemos el valor asociado al parámetro 'movie' de la URL
   
        const confiteriaParam = urlParams.get("confiteria");

    
       if (confiteriaParam) {
           try {
               const setepPyme = 1; // Variable que representa una configuración específica de Pyme

               // Despacha la acción para actualizar el pago temporal con la información relevante
               await dispatch(
                   geTemporaryPaymentUpdate(
                       {
                           // Información sobre la película activa y la cartelera
                           peliculaActiva: homeState.activeMovieInfo || [],
                           informacionPelicula: homeState.allMoviesBillboard || [],
                           extraInformacion: homeState.arrayInfoMovie || [],
                       },
                       {
                           // Información del proceso de compra
                           peliculaActiva: checkoutState.activeTanda || [],
                           precioDelista: checkoutState.step2 || [],
                           precioDelistaArray: checkoutState.step2?.priceList || [], // Se agrega el operador opcional para evitar errores
                           asientosSeleccionados: checkoutState.step3 || [],
                           articuloSeleccionado: checkoutState.step4?.articules || [], // Se agrega el operador opcional
                           timeToken: checkoutState.timeToken || [],
                           tokenSessions: checkoutState.tokenSessions || [],
                           payment: checkoutState.payment || [],
                       },
                       {
                           // Información del usuario autenticado
                           datosUsuario: authUser,
                       },
                       {
                           // Información bancaria relevante
                           informacionBanco: iformacionBan, // Se corrige el error tipográfico en la variable
                       },
                       setepPyme,
                       movieParam || confiteriaParam, // Se mantiene la condición para determinar el parámetro a enviar
                   ),
               );
           } catch (error) {
               console.error("Error al actualizar el pago temporal:", error); // Se agrega manejo de errores
           }
       }
    }, [dispatch, homeState, checkoutState, authUser]);

    useEffect(() => {
        insertTemporarySession();
    }, [insertTemporarySession]);
};

export default useInsertTemporarySession;
